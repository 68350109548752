<template>
	<v-card class="pa-4" :elevation="0">
		<div class="d-flex align-center justify-center text-center">
		<v-icon left color="success" x-large>mdi-check-circle-outline</v-icon>
		<h3 class="success--text">Todo ha ido bien</h3>
		</div>
		<h3 class="h5 mt-8">
			{{
				tarifa ?
				"Dentro de poco uno de nuestros agentes se pondrá en contacto contigo" :
				"Dentro de poco se enviará el contrato a tu email para que lo puedas firmar"
			}}
		</h3>
	</v-card>
</template>

<script>
export default {
	props: {
		tarifa: Boolean
	}
}
</script>

<style>

</style>